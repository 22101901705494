import { slickBlock } from "../util/slickBlock";

let sliderClass = "j-testimonials--slider";
let sliderArgs = {
	dots: true,
	arrows: false,
	adaptiveHeight: true,
	autoplay: true,
	autoplaySpeed: 5000
};
// Call a Gutenberg Slick object
slickBlock(sliderClass, sliderArgs, "testimonials");
